<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import customerModule from '@/store/main/customer'
import settingsCustomerModule from '@/store/settings/customer/customer-defaults'
// import organizationModule from '@/store/organization'

export default {
  name: 'Customers',
  setup() {
    const MODULE_NAME = 'customers'
    const SETTINGS_CUSTOMER_MODULE_NAME = 'settings-customer'
    // const MODULE_NAME_ORGANIZATION = 'organization'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, customerModule)
    if (!store.hasModule(SETTINGS_CUSTOMER_MODULE_NAME)) store.registerModule(SETTINGS_CUSTOMER_MODULE_NAME, settingsCustomerModule)
    // if (!store.hasModule(MODULE_NAME_ORGANIZATION)) store.registerModule(MODULE_NAME_ORGANIZATION, organizationModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
      if (store.hasModule(SETTINGS_CUSTOMER_MODULE_NAME)) store.unregisterModule(SETTINGS_CUSTOMER_MODULE_NAME)
      // if (store.hasModule(MODULE_NAME_ORGANIZATION)) store.unregisterModule(MODULE_NAME_ORGANIZATION)
    })
  },
}
</script>
